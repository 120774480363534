import React from 'react'
import { graphql } from 'gatsby'

import { Container } from 'react-bootstrap'
import Layout from '../components/Layout'
import SEO from '../components/seo'

class aboutUspage extends React.Component {
	render() {
		const siteTitle = 'About Us'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<Container className="py-5">
					<div className="services">
						<div className="container" />
						<p>
							EthosOpp is a Women-Owned company with a team of subject matter
							experts and one of the fastest growing Human Capital firms located
							in Denver, CO. With over 35 years of combined experience in
							staffing, payroll, human resources, and risk management, your
							business is not just getting a team of HR experts.
						</p>
						<p>
							Regardless of the industry, we will help you be the best organization you can be. Our core mission is to provide clients with reliable best practices and efficiencies to ensure sustainability and cost savings. By streamlining our Client's Human Resources, employment and back-office needs we make sure you're getting what your organization needs. Some of the services we help with:
						</p>
						<div className="row my-4">
							<div className="col">
								<ul className="about-list" id="about-list-page">
									<li>
										<i className="fa fa-check" />
										&nbsp;Independent Contractor compliance
									</li>
									<li>
										<i className="fa fa-check" />
										&nbsp;Hiring; permanent, contract or contract to hire
									</li>
									<li>
										<i className="fa fa-check" />
										&nbsp;Termination
									</li>
									<li>
										<i className="fa fa-check" />
										&nbsp;Employee handbooks
									</li>
									<li>
										<i className="fa fa-check" />
										&nbsp;Coaching and Mentoring
									</li>
									<li>
										<i className="fa fa-check" />
										&nbsp;Policy review
									</li>
									<li>
										<i className="fa fa-check" />
										&nbsp;Process optimization
									</li>
								</ul>
							</div><div className="col">
								<ul className="about-list" id="about-list-page">
									<li>
										<i className="fa fa-check" />
										&nbsp;Compensation analytics
									</li>
									<li>
										<i className="fa fa-check" />
										&nbsp;Performance management
									</li>
									<li>
										<i className="fa fa-check" />
										&nbsp;Risk assessment and mitigation
									</li>
									<li>
										<i className="fa fa-check" />
										&nbsp;Strategic planning
									</li>
									<li>
										<i className="fa fa-check" />
										&nbsp;Growth optimization
									</li>
									<li>
										<i className="fa fa-check" />
										&nbsp;Updates on changing federal and state regulations
									</li>
								</ul>
							</div>
						</div>
						<p>
							EthosOpp offers a range of employment solutions that cater
							directly and personally to our client’s business. We believe in
							working as a partner to our clients to improve their bottom line
							and cultivate comprehensive solutions to their employment and back
							office needs. We offer Professional HR and Placement services to
							clientele who have an established workforce but may need help
							identifying workers and processes that can support their business.
							We can provide contract to hire services and can serve as either
							the agency or employer of record for contractors while also
							providing a manageable employment solution removing administrative
							responsibility, risk, and liability.
						</p>
						<p>
							Regardless of the industry, we will prepare a plan to help you
							identify gaps in processes, acquire and retain world-class talent,
							and assist in servicing and retaining top tier customers. Our core
							mission is to provide clients with known best practices and
							efficient processes to ensure sustainability and cost savings by
							streamlining our Client’s HR, employment, and back-office needs.
						</p>
					</div>
				</Container>
			</Layout>
		)
	}
}
export default aboutUspage

export const aboutUspageQuery = graphql`
	query aboutUspageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
